import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardWithForcedLogin } from './shared/core';
import { NotLoggedInGuard } from './shared/core/authnotloggedinuser.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'internal'
  },
  {
    path: 'internal',
    loadChildren: () => import('./layout/internal/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuardWithForcedLogin],
  },
  {
    path: 'external',
    loadChildren: () => import('./layout/external/registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'services',
    loadChildren: () => import('./layout/services/services.module').then(m => m.ServicesModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
