import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './shared/core';
import { CoreInterceptor, ErrorHandlerInterceptor } from './shared/helper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { NotLoggedInGuard } from './shared/core/authnotloggedinuser.guard';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
// declare let config: Config;
// //declare let configProduction: Config;
// let allowUrls: any = [/*environment.production === true ? configProduction.api : */config.api,
// /*environment.production === true ? configProduction.authUrl :*/ config.authUrl]
@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    FormsModule,
    TranslateModule.forRoot(),
    AppRoutingModule,
    CoreModule.forRoot(/*environment.production === true ? configProduction.authConfig : */ environment.authConfig),
    BrowserAnimationsModule,
    BlockUIModule.forRoot(),
    BlockUIHttpModule.forRoot({
      requestFilters: [
        /auth.ishbek.com/,
        /auth.ishbek.com/,
        /prodauth.ishbek.com/,
        /prodauth.ishbek.com/,
        /api\/branch\//,
        /api\/Order\/getOrderByBranchesId/,
        /api\/Order\/getScheduledOrders/,
        // /api\/category\//,
        /api\/city\//,
        /api\/Reports\/GetDashboardReports/,
        /api\/Companyinfo\/GetProfileInfo/,
        /api\/Company\/Get/,
        /api\/Distance\/GetDeliveryPricing/,
        /api\/utils/,
        /api\/order\/getsound/,
        /api\/product\/getbycategory/,
        /api\/productAttribute\/GetManualProductAttributeValues/,
        /api\/productattribute\/GetsubAttributeValues/,
        // /api\/Distance\/GetBranchesForDistanceActive/,
        // /api\/distance\/GetBranchesForDistanceActive/,
        /api\/Customer\/GetCustomerAddressByAddressID/,
        /api\/discount\/GetNowDiscountForDeliveryByBranchId/,
        /api\/discount\/GetNowDiscountForTotalByBranchId/
        // /api\/category\/GetForBranchMenu/
        // /api\/product\/GetbyCategoryAvailableFromMenu/
      ]
      //links that you DONT want to block ui appears in (LOADING)
      /*
        /api\/Reports\//,
        /api\/Customer/,
        /api\/order\//,
        /api\/Order\//,
       */
    })], providers: [
      DatePipe,
      NotLoggedInGuard,
      // {
      //   provide: APPCONFIG_TOKEN,
      //   useValue: /*environment.production === true ? configProduction : */config,
      // },
      { provide: HTTP_INTERCEPTORS, useClass: CoreInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
      provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule { }
